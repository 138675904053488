<template>
    <layout-div>
        <div class="row justify-content-md-center mt-5">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title mb-4">Anmelden</h5>
                        <div v-if="validationErrors.general" class="alert alert-danger" role="alert">
                            {{ validationErrors.general }}
                        </div>
                        <form>
                            <div class="mb-3">
                                <label for="username" class="form-label">Username</label>
                                <input v-model="username" type="text" class="form-control" id="username" name="username" />
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">Password</label>
                                <input v-model="password" type="password" class="form-control" id="password"
                                    name="password" />
                            </div>
                            <div class="d-grid gap-2">
                                <button :disabled="isSubmitting" @click="loginAction()" type="button"
                                    class="btn btn-primary btn-block">Login</button>
                                <!--p class="text-center">Don't have account?
                                    <router-link to="/register">Register here </router-link>
                                </p-->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </layout-div>
</template>

   
<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';

export default {
    name: 'LoginPage',
    components: {
        LayoutDiv,
    },
    data() {
        return {
            username: '',
            password: '',
            validationErrors: {},
            isSubmitting: false,
        };
    },
    methods: {
        loginAction() {
            this.isSubmitting = true;
            let payload = {
                username: this.username,
                password: this.password,
            };

            axios.post('/users/authenticate', payload, { withCredentials: true })
                .then(response => {
                    if (response.data.id) {
                        localStorage.setItem('user_id', response.data.id);
                        this.$router.push('/dashboard');
                    } else {
                        this.validationErrors = 'Es gab ein Problem bei der Anmeldung. Bitte versuche es später erneut.';
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.isSubmitting = false;  // Anmeldevorgang ist abgeschlossen, setze isSubmitting zurück

                    this.validationErrors.general = error.response.data.message;

                });
        }

    },
};
</script>