<template>
    <layout-div>
        <div v-if="this.user">
            <div class="row justify-content-md-center">
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container-fluid">
                            <!-- Navbar Brand -->
                            <a class="navbar-brand" href="/dashboard">Home</a>
                            <a class="navbar-brand" href="/comments">Comments</a>
                            <!-- Toggler/Collapsible Button -->
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <!-- Navbar Links -->
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ms-auto">
                                    <li class="nav-item">
                                        <a @click="logoutAction" class="nav-link" aria-current="page" href="#">Logout</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <h4 class="text-center mt-5">Hello, <br><br>{{ this.user.firstName }}, {{ this.user.lastName }}</h4>
                </div>
            </div>

            <div class="container mt-5">
                <h2 class="mb-4">Discord Poster</h2>
                <div class="form-group">
                    <label for="commentTextarea">Comment</label>
                    <textarea class="form-control" id="commentTextarea" v-model="commentText" rows="7"></textarea>
                </div>

                <div class="form-group mt-3">
                    <label for="postTimeSelect">Post with Delay (If not selected, the comment will be posted immediately to
                        discord)</label>
                    <input type="datetime-local" class="form-control" id="postTimeSelect" v-model="postDateTime">
                </div>

                <div class="form-group mt-3">
                    <label for="commentTypeSelect">Comment Type</label>
                    <select class="form-control" id="commentTypeSelect" v-model="commentType">
                        <option value="DAILY_COMMENT">Daily Comment</option>
                        <option value="WEEKLY_COMMENT">Weekly Comment</option>
                    </select>
                </div>

                <div class="form-group mt-3">
                    <label for="additionalField">Mood</label>
                    <input type="text" class="form-control" id="additionalField" v-model="additionalInfo">
                </div>

                <button class="btn btn-primary mt-3" @click="submitComment">Broadcast to Discord</button>
                <br><br>
            </div>
        </div>
    </layout-div>
</template>

<script>
import axios from 'axios';
import LayoutDiv from '../LayoutDiv.vue';
import 'bootstrap-icons/font/bootstrap-icons.css';



export default {
    name: 'DashboardPage',
    components: {
        LayoutDiv,
    },
    data() {
        return {
            user: {},
            commentText: '',
            postDateTime: '',
            commentType: 'DAILY_COMMENT',
            additionalInfo: ''
        };
    },
    created() {
        this.getUser();
        this.setCurrentDateTime();
    },
    methods: {
        formatDateTime(dateTime) {
            const [date, time] = dateTime.split('T');
            return `${date} ${time}:00`; // Fügt Sekunden hinzu
        },
        async submitComment() {
            if (!this.additionalInfo.trim()) {
                alert('Mood not allowed to be empty!');
                return;
            }

            const userId = localStorage.getItem('user_id');
            const formattedDateTime = this.formatDateTime(this.postDateTime);
            const commentData = {
                comment: this.commentText,
                trigger_datetime: formattedDateTime,
                comment_type: this.commentType,
                mood: this.additionalInfo
            };

            try {
                await axios.post('/users/send_comment/' + userId, commentData, { withCredentials: true });
                alert('Successful: Sent comment to Discord');
                //window.location.reload(); // Lädt die Seite neu
                this.$router.push('/comments');
            } catch (error) {
                console.error('Fehler beim Senden des Kommentars:', error);
            }
        },
        setCurrentDateTime() {
            const now = new Date();
            const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
            this.postDateTime = localDateTime.slice(0, 16); // ISO-String in das korrekte Format für datetime-local umwandeln
        },
        async getUser() {
            const userId = localStorage.getItem('user_id');
            try {
                const response = await axios.get('/users/get_user_data/' + userId, { withCredentials: true });
                console.log(response);
                this.user = response.data.userData;
            } catch (error) {
                console.error('Error fetching combined data:', error);
                this.$router.push('/');
            }
        },
        logoutAction() {
            localStorage.clear();
            axios.post('/users/logout', {}, { withCredentials: true })
                .then(() => {
                    this.clearCookies();
                    this.$router.push('/');
                })
                .catch(error => {
                    console.error('Logout failed:', error);
                });
        },
        clearCookies() {
            document.cookie.split(";").forEach((c) => {
                document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        },
    }
};
</script>
