<template>
    <layout-div>
        <div class="row justify-content-md-center">
            <div class="col-12">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">
                        <!-- Navbar Brand -->
                        <a class="navbar-brand" href="/dashboard">Home</a>
                        <a class="navbar-brand" href="/comments">Comments</a>
                        <!-- Toggler/Collapsible Button -->
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <!-- Navbar Links -->
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <a @click="logoutAction" class="nav-link" aria-current="page" href="#">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <h4 class="text-center mt-5">Overview Comments</h4>
            </div>
        </div>
        <div class="container mt-5">
            <h2 class="mb-4">Comments in System</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Comment</th>
                        <th>Generated Question</th>
                        <th>Mood</th>
                        <th>Post Time</th>
                        <th>Comment Type</th>
                        <th>Posted in Discord</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="comment in comments" :key="comment.id">
                        <td>{{ comment.id }}</td>
                        <td>{{ comment.comment }}</td>
                        <td>{{ comment.generated_question }}</td>
                        <td>{{ comment.mood }}</td>
                        <td>{{ comment.trigger_datetime }}</td>
                        <td>{{ comment.comment_type }}</td>
                        <td>{{ comment.posted === 1 ? 'yes' : 'no' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </layout-div>
</template>
  
<script>
import LayoutDiv from '../LayoutDiv.vue';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from 'axios';

export default {
    name: 'CommentsPage',
    components: {
        LayoutDiv,
    },
    data() {
        return {
            comments: [],
        };
    },
    async created() {
        await this.fetchComments();
    },
    methods: {
        formatDateTime(dateTime) {
            return dateTime.replace('T', ' ').substring(0, 19); // Ersetzt 'T' durch ein Leerzeichen und entfernt die Millisekunden
        },
        async fetchComments() {
            try {
                const userId = localStorage.getItem('user_id');
                const response = await axios.get('/users/get_user_data/' + userId, { withCredentials: true });
                // Formatieren des Datums für jeden Kommentar
                this.comments = response.data.comments.map(comment => ({
                    ...comment,
                    trigger_datetime: this.formatDateTime(comment.trigger_datetime)
                }));
            } catch (error) {
                console.error('Fehler beim Abrufen der Kommentare:', error);
            }
        },
    },
};
</script>
  