import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from './components/pages/LoginPage';
import RegisterPage from './components/pages/RegisterPage';
import DashboardPage from './components/pages/DashboardPage';
import CommentsPage from './components/pages/CommentsPage';


// Import von CalendarView aus vue-simple-calendar
import { CalendarView } from 'vue-simple-calendar';

import './styles.css';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(function (config) {
    config.headers['x-api-key'] = process.env.VUE_APP_API_KEY;
    return config;
});

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: LoginPage },
        { path: '/register', component: RegisterPage },
        { path: '/dashboard', component: DashboardPage },
        { path: '/comments', component: CommentsPage },
    ],
});

const app = createApp(App);

// Registrierung von CalendarView global
app.component('CalendarView', CalendarView);

app.use(router).mount('#app');
